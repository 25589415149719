<template>
  <div>
    <h1>Content Writer</h1>
  </div>
</template>
<script>
  export default {
    name: 'CommunicationsContentWriter',
    data () {
      return {
        
      }
    },
    methods: {
      
    }
  }
</script>
<style lang="scss" scoped>
  
</style>