<template>
    <v-container>
      <h1 class="mb-4">AI Audio Generation</h1>
      <v-card>
        <v-tabs v-model="tab" class="px-4 pt-4">
          <v-tab>Proof of Concept</v-tab>
          <v-tab>Background</v-tab>
        </v-tabs>
        <v-divider></v-divider>
        <v-card-text class="text-left">
          <v-tabs-items v-model="tab">
            <!-- ------------ Proof of Concept ------------ -->
            <v-tab-item class="pa-6">
              <h2 class="mb-6">Proof of Concept</h2>
              <v-row class="pa-2">
                <v-col v-for="usecase in usecases" :key="usecase.audio" cols="3" xs="12" sm="6" md="3">
                  <v-card class="mx-auto">
                    <v-img
                      class="white--text align-end"
                      height="200px"
                      :src="usecase.image"
                      style="background: #f9f9f9"
                    >
                    </v-img>
                    <v-divider></v-divider>
                    <v-card-text class="text--primary">
                      <div v-if="usecase.name" class="font-weight-bold">{{ usecase.name }}</div>
                      Genre: {{ usecase.genre }}
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                      <v-btn v-if="usecase.status == 'stopped'" icon  @click="play(usecase)">
                        <v-icon>mdi-play</v-icon>
                      </v-btn>
                      <v-btn v-if="usecase.status == 'playing'" icon @click="stop()">
                        <v-icon>mdi-stop</v-icon>
                      </v-btn>
                      <div v-if="usecase.status == 'playing'" class="text-caption ml-2">Playing...</div>
                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-row>
            </v-tab-item>
            <!-- ------------ Background ------------ -->
            <v-tab-item class="pa-6">
              <h2 class="mb-6">Background</h2>
              <ul>
                <li>Advances in audio generation allows for human voice synthesis.</li>
                <li>While this experiment demonstrates music it can also be used for other audio applications.</li>
              </ul>
              <v-alert type="info" class="mt-6">
                Can we replace our audio interfaces with AI generated audio? Customer calls, podcasts, press releases, etc.
              </v-alert>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
      </v-card>
    </v-container>
  </template>
  <script>
  export default ({
    name: 'CommunicationsMusic',
    data: () => ({
      tab: 0,
      usecases: [
        {
          image: 'https://storage.googleapis.com/hydroottawa-audio/music/santa.webp',
          audio: 'https://storage.googleapis.com/hydroottawa-audio/music/Hydro%20Ottawa%20Christmas%201.mp3',
          name: "Hydro Glow",
          genre: 'christmas',
          player: null,
          status: 'stopped',
        },
        {
          image: 'https://storage.googleapis.com/hydroottawa-audio/music/Christmas.webp',
          audio: 'https://storage.googleapis.com/hydroottawa-audio/music/Hydro%20Ottawa%20Christmas%202.mp3',
          name: "Hydro Lights",
          genre: 'christmas',
          player: null,
          status: 'stopped',
        },
        {
          image: 'https://storage.googleapis.com/hydroottawa-audio/music/audio.png',
          audio: 'https://storage.googleapis.com/hydroottawa-audio/music/HydroOttawa5.mp3',
          genre: 'country',
          player: null,
          status: 'stopped',
        },
        {
          image: 'https://storage.googleapis.com/hydroottawa-audio/music/audio.png',
          audio: 'https://storage.googleapis.com/hydroottawa-audio/music/HydroOttawa4.mp3',
          genre: 'mellow dancepop',
          player: null,
          status: 'stopped',
        },
        {
          image: 'https://storage.googleapis.com/hydroottawa-audio/music/audio.png',
          audio: 'https://storage.googleapis.com/hydroottawa-audio/music/HydroOttawa1.mp3',
          genre: '90s rock',
          player: null,
          status: 'stopped',
        },
        {
          image: 'https://storage.googleapis.com/hydroottawa-audio/music/audio.png',
          audio: 'https://storage.googleapis.com/hydroottawa-audio/music/HydroOttawa9.mp3',
          genre: 'acoustic female vocals',
          player: null,
          status: 'stopped',
        },
        {
          image: 'https://storage.googleapis.com/hydroottawa-audio/music/audio.png',
          audio: 'https://storage.googleapis.com/hydroottawa-audio/music/HydroOttawa3.mp3',
          genre: 'dark delta blues',
          player: null,
          status: 'stopped',
        },
        {
          image: 'https://storage.googleapis.com/hydroottawa-audio/music/audio.png',
          audio: 'https://storage.googleapis.com/hydroottawa-audio/music/HydroOttawa2.mp3',
          genre: 'futuristic dream pop',
          player: null,
          status: 'stopped',
        },
        {
          image: 'https://storage.googleapis.com/hydroottawa-audio/music/audio.png',
          audio: 'https://storage.googleapis.com/hydroottawa-audio/music/HydroOttawa6.mp3',
          genre: 'syncopated classical',
          player: null,
          status: 'stopped',
        },
        {
          image: 'https://storage.googleapis.com/hydroottawa-audio/music/audio.png',
          audio: 'https://storage.googleapis.com/hydroottawa-audio/music/HydroOttawa8.mp3',
          genre: 'swing',
          player: null,
          status: 'stopped',
        },
        // {
        //   image: 'https://storage.googleapis.com/hydroottawa-audio/music/audio.png',
        //   audio: 'https://storage.googleapis.com/hydroottawa-audio/music/HydroOttawa7.mp3',
        //   genre: 'mellow grunge',
        //   player: null,
        //   status: 'stopped',
        // },
      ]
    }),
    methods: {
      stop() {
        // stop all other players
        this.usecases.forEach(usecase => {
          if (usecase.player) {
            usecase.player.pause()
            usecase.player.currentTime = 0
            usecase.status = 'stopped'
          }
        })
      },
      play(usecase) {
        // stop all other players
        this.stop()
        // play the current player
        usecase.player = new Audio(usecase.audio)
        usecase.player.currentTime = 0
        usecase.player.play()
        // detect when the song is done playing
        usecase.player.onended = () => {
          usecase.status = 'stopped'
        }
        usecase.status = 'playing'
      }
    }
  })
  </script>
  <style lang="scss">
  
  </style>