<template>
  <div class="d-flex flex-column">
    <div class="header" v-if="route != 'Landing' && user">
      <v-container>
        <div class="d-flex flex-row align-center">
          <!-- <img src="@/assets/skunkworks+border.png" height="40px" width="40px" contain> -->
          <v-btn icon color="black"><v-icon>mdi-lightning-bolt</v-icon></v-btn>
          <div class="overline ml-2">
            AI Skunkworks
          </div>
          <v-spacer></v-spacer>
          <div v-if="user" class="d-flex flex-row align-center">
            {{ user.displayName }}
            <v-btn icon @click="logout()" class="ml-2"><v-icon small>mdi-logout</v-icon></v-btn>
          </div>
        </div>
      </v-container>
    </div>
    <div v-if="user && hasPermissions('Employee')" class="subheader">
      <v-container class="py-0">
        <v-tabs background-color="primary" dark>
          <v-tab @click="go('Dashboard')">
            Dashboard
          </v-tab>
          <v-menu v-for="category in menu" :key="category.title" v-if="category?.children.length && hasPermissions(category.requirements)" offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-tab v-bind="attrs" v-on="on">
                {{ category.title }}
              </v-tab>
            </template>
            <v-list v-if="category.children.length">
              <div v-for="(child,index) in category.children" :key="category.title + index">
                <v-divider v-if="child.type === 'Divider' && hasPermissions(child.requirements)" class="my-2"></v-divider>
                <v-subheader v-else-if="child.type === 'subheader' && hasPermissions(child.requirements)" class="font-weight-bold">{{ child.title }}</v-subheader>
                <v-list-item v-else-if="hasPermissions(child.requirements)" link @click="go(child.route)">
                  <v-list-item-icon v-if="child?.requirements.includes('Pioneer')" class="mr-4">
                    <v-icon>mdi-atom</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title class="text-left">{{ child.title }}</v-list-item-title>
                </v-list-item>
              </div>
            </v-list>
          </v-menu>
        </v-tabs>
      </v-container>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Header',
  props: ['user'],
  data: () => ({
    route: null,
    menu: [
      { title: 'Communications', requirements: ['Communications'], children: [
        { title: 'Projects', type: 'subheader' },
        { title: 'Content Writer', route: 'CommunicationsContentWriter', requirements: ['Communications'] },
        // { type: 'Divider', requirements: ['Communications', 'Pioneer'] },
        { title: 'Experiments', type: 'subheader', requirements: ['Communications', 'Pioneer'] },
        { title: 'Imagen', route: 'CommunicationsImagen', requirements: ['Communications', 'Pioneer'] },
        { title: 'Music Demo', route: 'CommunicationsMusic', requirements: ['Communications', 'Pioneer'] },
        { title: 'Podcast Demo', route: 'CommunicationsPodcasts', requirements: ['Communications', 'Pioneer'] },
      ] },
      { title: 'Corporate', requirements: ['Corporate'], children: [
        // { title: 'Projects', type: 'subheader' },
        // { title: 'First Project', route: 'CorporateFirstProject', requirements: ['Corporate'] },
        // { type: 'Divider', requirements: ['Corporate', 'Pioneer'] },
        // { title: 'Experiments', type: 'subheader', requirements: ['Corporate', 'Pioneer'] },
        // { title: 'First Experiment', route: 'CorporateFirstExperiment', requirements: ['Corporate', 'Pioneer'] },
      ] },
      { title: 'Customer Service', requirements: ['Customer Service'], children: [
        { title: 'Projects', type: 'subheader' },
        { title: 'Agent Assist', route: 'CustomerServiceAgentAssist', requirements: ['Customer Service'] },
        { title: 'Call Analysis', route: 'CustomerServiceCallAnalysis', requirements: ['Customer Service'] },
        { title: 'Smart Search', route: 'CustomerServiceSmartSearch', requirements: ['Customer Service'] },
        { type: 'Divider', requirements: ['Customer Service', 'Pioneer'] },
        { title: 'Experiments', type: 'subheader', requirements: ['Customer Service', 'Pioneer'] },
        { title: 'Artificial Agent', route: 'CustomerServiceAgent', requirements: ['Customer Service', 'Pioneer'] },
      ] },
      { title: 'Distribution', requirements: ['Distribution'], children: [
        // { title: 'Projects', type: 'subheader' },
        // { title: 'First Project', route: 'DistributionFirstProject', requirements: ['Distribution'] },
        // { type: 'Divider', requirements: ['Distribution', 'Pioneer'] },
        // { title: 'Experiments', type: 'subheader', requirements: ['Distribution', 'Pioneer'] },
        // { title: 'First Experiment', route: 'DistributionFirstExperiment', requirements: ['Distribution', 'Pioneer'] },
      ] },
      { title: 'Finance', requirements: ['Finance'], children: [
        // { title: 'Projects', type: 'subheader' },
        // { title: 'First Project', route: 'FinanceFirstProject', requirements: ['Finance'] },
        // { type: 'Divider', requirements: ['Finance', 'Pioneer'] },
        { title: 'Experiments', type: 'subheader', requirements: ['Finance', 'Pioneer'] },
        { title: 'Bill Prediction', route: 'FinanceBillPrediction', requirements: ['Finance', 'Pioneer'] },
      ] },
      { title: 'Generation', requirements: ['Generation'], children: [
        // { title: 'Projects', type: 'subheader' },
        // { title: 'First Project', route: 'GenerationFirstProject', requirements: ['Generation'] },
        // { type: 'Divider', requirements: ['Generation', 'Pioneer'] },
        // { title: 'Experiments', type: 'subheader', requirements: ['Generation', 'Pioneer'] },
        // { title: 'First Experiment', route: 'GenerationFirstExperiment', requirements: ['Generation', 'Pioneer'] },
      ] },
      { title: 'Human Resources', requirements: ['Human Resources'], children: [
        // { title: 'Projects', type: 'subheader' },
        // { title: 'First Project', route: 'HumanResourcesFirstProject', requirements: ['Human Resources'] },
        // { type: 'Divider', requirements: ['Human Resources', 'Pioneer'] },
        { title: 'Experiments', type: 'subheader', requirements: ['Human Resources', 'Pioneer'] },
        { title: 'Workforce Automation', route: 'HumanResourcesWorkforceAutomation', requirements: ['Human Resources', 'Pioneer'] },
      ] },
      { title: 'Infrastructure', requirements: ['Infrastructure'], children: [
        // { title: 'Projects', type: 'subheader' },
        // { title: 'First Project', route: 'InfrastructureFirstProject', requirements: ['Infrastructure'] },
        // { type: 'Divider', requirements: ['Infrastructure', 'Pioneer'] },
        // { title: 'Experiments', type: 'subheader', requirements: ['Infrastructure', 'Pioneer'] },
        // { title: 'First Experiment', route: 'InfrastructureFirstExperiment', requirements: ['Infrastructure', 'Pioneer'] },
      ] },
      { title: 'Legal', requirements: ['Legal'], children: [
        // { title: 'Projects', type: 'subheader' },
        // { title: 'First Project', route: 'LegalFirstProject', requirements: ['Legal'] },
        // { type: 'Divider', requirements: ['Legal', 'Pioneer'] },
        // { title: 'Experiments', type: 'subheader', requirements: ['Legal', 'Pioneer'] },
        // { title: 'First Experiment', route: 'LegalFirstExperiment', requirements: ['Legal', 'Pioneer'] },
      ] },
      { title: 'Operations', route: '', requirements: ['Operations'], children: [
        { title: 'Projects', type: 'subheader' },
        { title: 'Switch Orders', route: 'OperationsSwitchOrders', requirements: ['Operations'] },
        { type: 'Divider', requirements: ['Operations', 'Pioneer'] },
        { title: 'Experiments', type: 'subheader', requirements: ['Operations', 'Pioneer'] },
        { title: 'Nameplate Automation', route: 'OperationsNameplateAutomation', requirements: ['Operations', 'Pioneer'] },
        { title: 'Damage Assessment', route: 'OperationsDamageAssessment', requirements: ['Operations', 'Pioneer'] },
        { title: 'Forestry', route: 'OperationsForestry', requirements: ['Operations', 'Pioneer'] },
      ] },
      { title: 'Regulatory', route: '', requirements: ['Regulatory'], children: [
        { title: 'Projects', type: 'subheader' },
        { title: 'Rate Application Assistant', route: 'RegulatoryRateApplication', requirements: ['Regulatory'] },
      ] },
      { title: 'Technology', requirements: ['Technology'], children: [
        // { title: 'Projects', type: 'subheader' },
        // { title: 'First Project', route: 'TechnologyFirstProject', requirements: ['Technology'] },
        // { type: 'Divider', requirements: ['Pioneer'] },
        { title: 'Experiments', type: 'subheader', requirements: ['Pioneer'] },
        { title: 'Google Chat Assistant', route: 'TechnologyChatAssistant', requirements: ['Pioneer'] },
      ] },
    ],
  }),
  created() {
    this.route = this.$route.name
  },
  methods: {
    logout() {
      this.$emit('logout')
    },
    hasPermissions(requirements) {
      // check that the user exists
      if (!this.user) return false
      // return true if the user is an admin
      if (this.user?.roles.includes('Admin')) return true
      // return true if there are no requirements
      if (!requirements) return true
      // requirements can be a string or an array, so convert to array
      if (typeof requirements === 'string') requirements = [requirements]
      
      // check that the user has all the required roles
      return requirements.every(requirement => this.user.roles.includes(requirement))
    },
  },
  watch: {
    $route(to, from) {
      this.route = to.name
    },
  }
}
</script>
<style lang="scss">
.header {
  background: white;
  z-index: 1;
  -webkit-box-shadow: 0px 0px 9px 3px rgba(41,41,41,.25);
  -moz-box-shadow: 0px 0px 9px 3px rgba(41,41,41,.25);
  box-shadow: 0px 0px 9px 3px rgba(41,41,41,.25);
}
.subheader {
  background: black;
  -webkit-box-shadow: 0px 0px 9px 3px rgba(41,41,41,.25);
  -moz-box-shadow: 0px 0px 9px 3px rgba(41,41,41,.25);
  box-shadow: 0px 0px 9px 3px rgba(41,41,41,.25);
}
</style>